


































import {Component, Prop} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import NftToggleButton from '@/components/misc/GenericToggleButton.vue'
import NftCollectionToggleButton from '@/components/misc/GenericToggleButton.vue'
import GenericToggleButton from '@/components/misc/GenericToggleButton.vue'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'

@Component({
  components: {
    GenericToggleButton,
    NftCollectionToggleButton,
    NftToggleButton,
    BounceLoader,
  },
})
export default class LastPriceUpdated extends MixinScreenSize {
  @Prop({type: String, required: true}) lastTimeUpdated!: string
  @Prop({type: Boolean, default: false}) displayInline!: boolean
}
