var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scrollable_table",class:{
    'scrollable_table--homepage': _vm.isHomepage,
    'scrollable_table--dapp-detailed-page': _vm.isDappDetailedPage,
    'scrollable_table--tokens-page': _vm.isTokensPage,
  }},[_c('div',{staticClass:"scrollable_table__table-container relative rounded-lg",class:{
      shadow: _vm.isMobile,
      border: !_vm.isHomepage,
    }},[_c('table',{staticClass:"table table--ndapp"},[_c('thead',[_c('tr',_vm._l((_vm.allFields),function(value,key){return _c('th',{key:key},[_c('span',[_vm._v(" "+_vm._s(_vm.getHeaderLabel(value))+" ")])])}),0)]),(_vm.collection.items)?_c('tbody',[_vm._l((_vm.collection.items),function(item,i){return [_c('tr',{key:item.$id},_vm._l((_vm.allFields),function(value,key){return _c('td',{key:key,class:{
                'sticky right-0': _vm.showMarketInformationLabel(value, i),
              },attrs:{"colspan":_vm.showMarketInformationLabel(value, i) ? 2 : 1}},[_c('render-schema',{attrs:{"field":value,"schema":_vm.schema},model:{value:(_vm.collection.items[i]),callback:function ($$v) {_vm.$set(_vm.collection.items, i, $$v)},expression:"collection.items[i]"}})],1)}),0)]})],2):_vm._e()]),_c('div',{staticClass:"w-full flex justify-center"},[_c('moon-loader',{attrs:{"loading":_vm.collection.items.length <= 0}})],1)]),(_vm.showViewAll)?_c('div',{staticClass:"flex items-center-center md:justify-end pb-10 md:pb-16 pt-6 md:pt-8 md:pr-10 mt-8 md:mt-0"},[_c('router-link',{staticClass:"horiz gap-2 bg-black btn btn--contrast h-10",attrs:{"to":_vm.viewAllUrl}},[_c('span',{staticClass:"text-lg font-medium"},[_vm._v(" "+_vm._s(_vm.viewAllText)+" ")]),_c('em',{staticClass:"text-2xl text-aquamarine fas fa-angle-right"})])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }